<template>
	<div class="login-page">
		<div style="padding:70px 0;margin-left: 20px;">
			<img src="/images/img_denglu.png" style="width: 180px;">
		</div>
		<div class="posistiontop">			
			<van-form @submit="onSubmit" :show-error="false" :validate-first="true">
				<div class="form-label">手机号</div>
				<van-field v-model="phone" type="tel" name="phone" class="no-bg" label="" placeholder="请填写手机号" :rules="rules.phone" />
				<div class="form-label">短信验证码</div>
				<van-field v-model="code" center clearable name="code" class="no-bg" label="" placeholder="请填写短信验证码" :rules="rules.code" maxlength="4">
					<template #button>
						<span v-if="showcut">
							<span @click="getCode">获取验证码</span>
						</span>
						<span v-else class="cutdown">
							<van-count-down :time="time" format="ss" @finish="finish" />秒后重新发送
						</span>
					</template>
				</van-field>
				<div style="position: fixed;left:20px;right:20px;bottom: 40px;">
					<van-button block type="primary" native-type="submit" color="#2D6FDF" round>登录</van-button>
				</div>
			</van-form>
		</div>
	</div>
</template>

<script>
	import {Button,Form,Field,CountDown,Image as VanImage,} from "vant";
	import { login, getAuth, getToken } from "@api/system";
	import { loginSend } from "@api/verification";
	import { getCookie , setCookie } from "@/util/storage.js";
	// import storage from "../util/storage";
	export default {
		name: "login",
		data() {
			return {
				phone: "",
				code: "",
				wxCode:"",
				time: 60000,
				showcut: true,
				// wxUserInfo: "",
				rules:{
					phone:[[{ required: true,pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,message: '请填写正确的手机号' }]],
					code:[{ required: true,pattern:/^([0-9]+[\d]*(.[0-9]{1,2})?)$/,message: '验证码为4位数字' }]
				}
			};
		},

		components: {
			[Form.name]: Form,
			[Button.name]: Button,
			[Field.name]: Field,
			[CountDown.name]: CountDown,
			[VanImage.name]: VanImage,
		},
		
		created() {
			console.log(this.$route.query);
			// let wxCode = getCookie("wxCode");
			let { code } = this.$route.query;
			if(code){
				this.wxCode = code;
			}
		},

		methods: {
			//获取手机验证码
			getCode() {
				if (this.phone == "") {
					this.$toast("请先输入手机号");
				} else {
					loginSend({
						phone: this.phone
					}).then((res) => {
						if (res.respCode == 200) {
							this.showcut = false;

							this.$toast("发送成功");
						} else {
							this.$toast(res.respMsg);
						}
					});
				}
			},
			//倒计时结束
			finish() {
				this.showcut = true;
			},
			//登录
			onSubmit(values) {
				console.log("submit", values);
				const params = {
					...values,
				};
				login(params).then((res) => {
					if (res.respCode == 200) {
						
						if(res.result.registerStatus=='1'){		   //  registerStatus  1：未注册   2：注册  					
							this.$router.replace({
								path:'/register',
								query:{
									id:res.result.id,
									wxCode:this.wxCode,
									phone:this.phone,
								}
							});
						}else if(res.result.registerStatus=='2'){
							this.$toast("登录成功");
							if(res.result.token){
								window.localStorage.setItem("token", res.result.token);
							};
							if(res.result.user){
								setCookie('userinfo',res.result.user);
							};	
								
							let redirect = getCookie('redirect')
							if(redirect){
								this.$router.replace(redirect)
							}else{
								this.$router.replace('/personal')
							};
						};
						
					} else {
						this.$toast(res.respMsg);
					}
				});
			},
		},
		mounted() {
			
		},
	};
</script>
<style lang="less" scoped>
	.login-page{
		background: url(/images/img_bg.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	
	.form-label{
		margin: 10px 15px 0;font-size:16px;font-weight:bold;
	}
	
	.cutdown {
		font-size: 12px; color: #999;
	}

	.van-count-down {
		display: inline;
	}

	.headbg {
		background-image: url("../assets/images/lobg.png");
		background-size: cover;
		width: 100%;
		height: 210px;
	}

	.posistiontop {
		padding:0 5px;		
	}
	
	.no-bg{padding-top:5px;background: transparent;}
	
</style>
